@font-face {
    font-family: TTNormsRegular;
    src: url("assets/fonts/TTNorms-Regular.otf") format("otf");
}

body {
    font-family: TTNormsRegular
}

.green-button, .green-button:hover, .green-button:visited, .green-button:active {
    background-color: #00bf71 !important;
    border-color: #00bf71 !important;
}

.grey-button, .grey-button:hover, .grey-button:visited, .grey-button:active {
    background-color: #888B8D !important;
    border-color: #888B8D !important;
}

.green-background {
    background-color: #00bf71 !important;
}

.grey-background {
    background-color: #888B8D !important;
}

.green-color {
    color: #00bf71 !important;
}

.grey-color {
    color: #888B8D !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #FFF !important;
}

#navBarUser .navbar-nav .active > .nav-link, #navBarUser .navbar-nav .nav-link.active, #navBarUser .navbar-nav .nav-link.show, #navBarUser .navbar-nav .show > .nav-link {
    border-bottom: 1px solid #888B8D;
}

.no-border-bottom {
    border-bottom: none !important;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #00bf71 !important;
}

.nav-pills .nav-link {
    color: #888B8D;
}

.table-title-style, .table-title-style > th {
    color: #FFF;
    font-weight: unset;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #00bf71;
}

.hidden-password:hover, .hidden-password:active {
    background-color: #888B8D !important;
    border-color: #888B8D !important;
}

.hidden-password {
    border-color: #888B8D !important;
}

#createUserForm hr {
    margin-top: 0px;
}

.margin-top {
    margin-top: 3%
}

.color-a {
    color: white;
    padding: 5px;
    padding-bottom: 15%;
}

.color-a:hover {
    color: white;
    text-decoration: none;
}

.color-a:active {
    border-bottom: 1px solid #888B8D;
}